<template>
    <div class="container">

        <Address-form :modal=modal @submitted="create"></Address-form>

    </div>
</template>

<script>
import AddressForm from '../../components/AddressForm.vue'


export default {
    components: { AddressForm },
    middleware: ['auth','user'],
    props:['modal'],
    methods: {
        create (data) {
            //console.log(data);
             let item = data.item;

          this.$http.post('/api/address', item)
                 .then(({ data }) => {
                     //this.$router.push({ name: 'address' })
                     console.log("CreateAddressPage");
                     this.$emit('closeAddressCreateModal');
                     this.$router.go(-1)
                     this.$toast.success(data.message, "Success")
                 })
                 .catch(({ response }) => {
                     // send eror msg globaly
                     //EventBus.$emit('error', response.data.errors)
                    console.log('error', response.data.errors);
                 })
        }
    }
}
</script>